import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import { Row, message, Button, Col, Input, Modal, Form } from "antd";
import moment from "moment";
import HELPERS from "../../utils/helper";
function ViewChallenge({ view_user, all_admin }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [bonus, setBonus] = useState(25);
  const [roomCodeData, setRoomCodeData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [paneltyId, setPaneltyId] = useState();
  const [form] = Form.useForm();
  const checkRoomCode = async (Room_code) => {
    try {
      // const url = `https://pardeep.morniinc.in/result?code=${Room_code}`;
      // const options = {
      //   method: "GET",
      //   headers: {
      //     "X-RapidAPI-Key":
      //       "127c9351d5msh54b0b948e7da6ebp121 e59jsn9163e2425ed7",
      //     "X-RapidAPI-Host": "ludo-king-room-code-api.p.rapidapi.com",
      //   },
      // };
      // let checkCodeRes;
      // checkCodeRes = await fetch(url, options);
      // let result = await checkCodeRes.text();
      // result = JSON.parse(result);
      // setRoomCodeData(result);
      // const response = await API_MANAGER.checkRoomCode({
      //   roomCode: Room_code,
      // });
      // let result = await checkCodeRes.text();
      // result = JSON.parse(result);
      // console.log(response?.data?.data, "response");
      // setRoomCodeData(response?.data?.data);
      const response = await API_MANAGER.checkRoomCode({
        roomCode: Room_code,
      });
      // let result = await checkCodeRes.text();
      // result = JSON.parse(result);

      setRoomCodeData(response?.data?.data);
    } catch (error) {
      // message.error("Something went wrong.");
    }
  };

  function winnAmount(gameAmount) {
    let profit = null;
    if (gameAmount >= 10 && gameAmount <= 49) profit = (gameAmount * 20) / 100;
    else if (gameAmount >= 50 && gameAmount <= 250)
      profit = (gameAmount * 10) / 100;
    else if (gameAmount > 250 && gameAmount <= 500) profit = 25;
    else if (gameAmount > 500) profit = (gameAmount * 5) / 100;
    return gameAmount - profit;
  }
  const getData = async () => {
    try {
      const response = await API_MANAGER.getChallengeById(id);
      const decryptedData = HELPERS.decrypt(response?.data?.data);

      setData(decryptedData);
      if (decryptedData?.game_id) await checkRoomCode(decryptedData?.game_id);
    } catch (error) {
      // message.error("Something went wrong!");
    }
  };
  const cancelChallenge = async () => {
    const confirm = window.confirm("are you sure to cancel");

    if (confirm) {
      try {
        const response = await API_MANAGER.cancelChallenge(id);
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const updateChallangeInProgress = async () => {
    const confirm = window.confirm(
      "are you sure you want update InProgress to false."
    );

    if (confirm) {
      try {
        await API_MANAGER.updateChallangeInProgress(id);
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const updateByAdmin = async (userId) => {
    const confirm = window.confirm("are you sure to update");
    if (confirm) {
      try {
        const response = await API_MANAGER.updateChallengeAdmin(
          {
            winner: userId,
          },
          id
        );
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const updateChallengeAdmin25Percent = async (userId) => {
    const confirm = window.confirm("are you sure to update");
    if (confirm) {
      try {
        const response = await API_MANAGER.updateChallengeAdmin25Percent(
          {
            winner: userId,
          },
          id
        );
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const updateChallengeAdmin50Percent = async (userId) => {
    const confirm = window.confirm("are you sure to update");
    if (confirm) {
      try {
        const response = await API_MANAGER.updateChallengeAdmin50Percent(
          {
            winner: userId,
          },
          id
        );
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const updatePenalty = async (data) => {
    const confirm = window.confirm(
      "Are you sure, you want to add penalty to this user?"
    );
    if (confirm) {
      try {
        const response = await API_MANAGER.addPanelty(
          { bonus: JSON.parse(data?.number), txn_msg: data?.reason },
          paneltyId
        );
        if (response?.data?.data?.status === 0) {
          alert("User wallet balance already low.");
        } else {
          alert("Penalty successfully added.");
        }
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  useEffect(() => {
    if (id) getData();
  }, [id]);
  return (
    <div className="viewGameContainer">
      <Modal
        title="Penalty"
        footer={false}
        centered
        open={isVisible}
        onCancel={() => {
          form.resetFields();
          setIsVisible(false);
          setPaneltyId();
        }}
      >
        <Form form={form} onFinish={updatePenalty}>
          <Form.Item
            name="number"
            rules={[
              {
                required: true,
                message: "Please enter amount.",
              },
            ]}
          >
            <Input type="number" max={250000} placeholder="Enter Amount" />
          </Form.Item>{" "}
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "Please enter reason.",
              },
            ]}
          >
            <Input placeholder="Enter reason" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className="boxContainer">
        <Row>Match Details</Row>
        {data?.Status != "cancelled" &&
          data?.Status != "completed" &&
          data?.Status != "pending" && (
            <Row justify={"end"} gutter={[8, 8]}>
              <Col>
                <Button onClick={() => updateChallangeInProgress()}>
                  InProgress
                </Button>
              </Col>
              <Col>
                <Button onClick={() => cancelChallenge()}>Cancel</Button>
              </Col>
            </Row>
          )}

        <Row>
          <p>Check participants data, and announced result.</p>
        </Row>
        <Row align={"middle"} gutter={20}>
          <Col xs={24} md={8} lg={6}>
            <p>Match Fee: {data?.Game_Ammount}</p>
            {/* <p>{</p> */}
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>Prize: {data?.Game_Ammount + winnAmount(data?.Game_Ammount)}</p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>Type: {data?.Game_Ammount + winnAmount(data?.Game_Ammount)}</p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>Status: {data?.Status}</p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>Room Code: {data?.Room_code}</p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>
              Game Updated By:{" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {data?.action_by && data?.action_by?.Name} -{" "}
                {data?.action_by && data?.action_by?.Phone}
              </span>
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {data?.actionby_Date &&
                  `(${moment(data?.actionby_Date).format("LLL")})`}
              </span>
            </p>
          </Col>
          <Col xs={24}>
            <p>
              Ludo King Game Status:{" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {roomCodeData?.game_status}
              </span>
            </p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>
              Exit:{" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {roomCodeData?.isExit ? "Yes" : "No"}
              </span>
            </p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>
              Auto Exit:{" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {roomCodeData?.isAutoExit ? "Yes" : "No"}
              </span>
            </p>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Row gutter={[20, 20]}>
          <Col xs={24} lg={12}>
            <div className="boxContainer">
              <h3>Creator</h3>
              {(data?.Status == "pending" ||
                data?.Status == "conflict" ||
                (data?.Status === "running" && all_admin)) && (
                <div className="form-group">
                  <Row align={"middle"} gutter={[20, 20]}>
                    <Col xs={12} lg={6}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          updateChallengeAdmin25Percent(data?.Created_by?._id);
                        }}
                      >
                        Win 25%
                      </Button>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          updateChallengeAdmin50Percent(data?.Created_by?._id);
                        }}
                      >
                        Win 50%
                      </Button>
                    </Col>

                    <Col xs={12} lg={6}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          updateChallengeAdmin25Percent(data?.Accepetd_By?._id);
                        }}
                      >
                        Lose 25%
                      </Button>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          updateChallengeAdmin50Percent(data?.Accepetd_By?._id);
                        }}
                      >
                        Lose 50%
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              <div>
                <p>
                  User Name:{" "}
                  <span
                    className="tableLink"
                    onClick={() => {
                      if (data?.Created_by?._id && view_user) {
                        navigate(`/user/view/${data?.Created_by?._id}`);
                      }
                    }}
                  >
                    {data?.Created_by && data?.Created_by?.Name}
                  </span>
                </p>
              </div>
              <div>
                <p className="ludoKingName">
                  Ludo King Name:{" "}
                  <span className="">
                    {roomCodeData?.creator_name
                      ? roomCodeData?.creator_name
                      : data?.Creator_LudoKing_Name}
                    {/* {roomCodeData?.creator_name} */}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  Hold Balance: <span>{data?.Created_by?.hold_balance}</span>
                </p>
              </div>
              <div>
                <p>
                  Created Time:{" "}
                  <span>{moment(data?.createdAt).format("LLL")}</span>
                </p>
              </div>
              <div>
                <p className="participation_status">
                  Participant Status: <span>{data?.Creator_Status}</span>
                </p>
              </div>
              <div>
                <p className="ludoKingName">
                  Ludo King Status:{" "}
                  <span>
                    {roomCodeData && roomCodeData?.winner_id
                      ? roomCodeData?.winner_id === roomCodeData?.creator_id
                        ? "Won"
                        : "Lose"
                      : "Not Found"}
                  </span>
                </p>
                <p className="ludokinggoti">
                  1st Goti:{" "}
                  <span>{roomCodeData?.tokenPositions?.creator?.L0}</span>
                </p>
                <p className="ludokinggoti">
                  2nd Goti:{" "}
                  <span>{roomCodeData?.tokenPositions?.creator?.L1}</span>
                </p>
                <p className="ludokinggoti">
                  3rd Goti:{" "}
                  <span>{roomCodeData?.tokenPositions?.creator?.L2}</span>
                </p>
                <p className="ludokinggoti">
                  4th Goti:{" "}
                  <span>{roomCodeData?.tokenPositions?.creator?.L3}</span>
                </p>
              </div>
              {data?.Creator_Status_Updated_at && (
                <div>
                  <p>
                    Status Updated At:{" "}
                    <span>
                      {moment(data?.Creator_Status_Updated_at).format("LLL")}
                    </span>
                  </p>
                </div>
              )}
              {data?.Creator_Status_Reason && (
                <div>
                  <p>
                    Cancel Reason: <span>{data?.Creator_Status_Reason}</span>
                  </p>
                </div>
              )}
              <Row gutter={[20, 20]} className="mb-20">
                {data?.Creator_Screenshot && (
                  <Col span={12}>
                    <div>
                      <p>Win Screenshot:</p>
                      <img
                        src={`https://khiladiludo.s3.ap-south-1.amazonaws.com/${data?.Creator_Screenshot}`}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="not loaded"
                      />
                    </div>
                  </Col>
                )}
                {data?.Creator_Start_Screenshot && (
                  <Col span={12}>
                    <div>
                      <p>Game Start Screenshot:</p>
                      <img
                        src={`https://khiladiludo.s3.ap-south-1.amazonaws.com/${data?.Creator_Start_Screenshot}`}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="not loaded"
                      />
                    </div>
                  </Col>
                )}
                {data?.Creator_OneGoti_Screenshot && (
                  <Col span={12}>
                    <div>
                      <p>One Goti Open Screenshot:</p>
                      <img
                        src={`https://khiladiludo.s3.ap-south-1.amazonaws.com/${data?.Creator_OneGoti_Screenshot}`}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="not loaded"
                      />
                    </div>
                  </Col>
                )}
              </Row>
              {(data?.Status == "pending" ||
                data?.Status == "conflict" ||
                (data?.Status === "running" && all_admin)) && (
                <div className="form-group">
                  <Row align={"middle"} gutter={[20, 20]}>
                    <Col xs={12} lg={6}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          updateByAdmin(data?.Created_by?._id);
                        }}
                      >
                        Win
                      </Button>
                    </Col>

                    <Col xs={12} lg={6}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          updateByAdmin(data?.Accepetd_By?._id);
                        }}
                      >
                        Lose
                      </Button>
                    </Col>
                  </Row>
                  <div>
                    <p>Add Penalty</p>
                    <Row gutter={16}>
                      <Col>
                        <Button
                          onClick={() => {
                            setPaneltyId(data?.Created_by?._id);
                            setIsVisible(true);
                          }}
                        >
                          Add Penalty
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="boxContainer">
              <h3>Acceptor</h3>
              {(data?.Status == "pending" ||
                data?.Status == "conflict" ||
                (data?.Status === "running" && all_admin)) && (
                <div className="form-group">
                  <Row align={"middle"} gutter={[20, 20]}>
                    <Col xs={12} lg={6}>
                      <Button
                        onClick={() => {
                          updateChallengeAdmin25Percent(data?.Accepetd_By?._id);
                        }}
                        className="w-100"
                      >
                        Win 25%
                      </Button>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Button
                        onClick={() => {
                          updateChallengeAdmin50Percent(data?.Accepetd_By?._id);
                        }}
                        className="w-100"
                      >
                        Win 50%
                      </Button>
                    </Col>

                    <Col xs={12} lg={6}>
                      <Button
                        onClick={() => {
                          updateChallengeAdmin25Percent(data?.Created_by?._id);
                        }}
                        className="w-100"
                      >
                        Lose 25%
                      </Button>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Button
                        onClick={() => {
                          updateChallengeAdmin50Percent(data?.Created_by?._id);
                        }}
                        className="w-100"
                      >
                        Lose 50%
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              <div>
                <p>
                  User Name:{" "}
                  <span
                    className="tableLink"
                    onClick={() => {
                      if (data?.Accepetd_By?._id && view_user) {
                        navigate(`/user/view/${data?.Accepetd_By?._id}`);
                      }
                    }}
                  >
                    {data?.Accepetd_By && data?.Accepetd_By?.Name}
                  </span>
                </p>
              </div>
              <div>
                <p className="ludoKingName">
                  Ludo King Name:{" "}
                  <span className="">
                    {/* {roomCodeData?.creator_id === roomCodeData?.player1_id
                      ? roomCodeData?.player2_name
                      : roomCodeData?.player1_name} */}
                    {/* {roomCodeData?.player1name} */}
                    {roomCodeData?.player_name
                      ? roomCodeData?.player_name
                      : data?.Acceptor_LudoKing_Name}
                    {/* {roomCodeData?.player_name} */}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  Hold Balance: <span>{data?.Accepetd_By?.hold_balance}</span>
                </p>
              </div>
              <div>
                <p>
                  Join Time:{" "}
                  <span>
                    {moment(data?.Acceptor_by_Creator_at).format("LLL")}
                  </span>
                </p>
              </div>
              <div>
                <p className="participation_status">
                  Participant Status:{" "}
                  <span className="">
                    {data?.Acceptor_status ? data?.Acceptor_status : "----"}
                  </span>
                </p>
              </div>{" "}
              <div>
                <p className="ludoKingName">
                  Ludo King Status:{" "}
                  <span>
                    {/* {roomCodeData?.creator_id === roomCodeData?.player1_id &&
                    roomCodeData?.player1_status
                      ? roomCodeData?.player2_status
                      : "Not Found"} */}
                    {/* {roomCodeData?.player1status
                      ? roomCodeData?.player1status
                      : "Not Found"} */}

                    {/* {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.eStatus ||
                        "Not Found"
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.eStatus ||
                        "Not Found"
                      : "Not Found"} */}
                    {roomCodeData && roomCodeData?.winner_id
                      ? roomCodeData?.winner_id === roomCodeData?.player_id
                        ? "Won"
                        : "Lose"
                      : "Not Found"}
                  </span>
                </p>
                <p className="ludokinggoti">
                  1st Goti:{" "}
                  <span>
                    {/* {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L0"
                        ] || "0"
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L0"
                        ] || "0"
                      : ""}
                    {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L0"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                            "L0"
                          ] === -1
                        ? " (Not Opened)"
                        : "" || ""
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L0"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                            "L0"
                          ] === -1
                        ? "( Not Opened)"
                        : "" || ""
                      : ""} */}
                    {roomCodeData?.tokenPositions?.player?.L0}
                  </span>
                </p>
                <p className="ludokinggoti">
                  2nd Goti:{" "}
                  <span>
                    {/* {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L1"
                        ] || "0"
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L1"
                        ] || "0"
                      : ""}
                    {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L1"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                            "L1"
                          ] === -1
                        ? " ( Not Opened)"
                        : "" || ""
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L1"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                            "L1"
                          ] === -1
                        ? " (Not Opened)"
                        : "" || ""
                      : ""} */}
                    {roomCodeData?.tokenPositions?.player?.L1}
                  </span>
                </p>
                <p className="ludokinggoti">
                  3rd Goti:{" "}
                  <span>
                    {" "}
                    {/* {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L2"
                        ] || "0"
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L2"
                        ] || "0"
                      : ""}
                    {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L2"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                            "L2"
                          ] === -1
                        ? " (Not Opened)"
                        : "" || ""
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L2"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                            "L2"
                          ] === -1
                        ? " (Not Opened)"
                        : "" || ""
                      : ""} */}
                    {roomCodeData?.tokenPositions?.player?.L2}
                  </span>
                </p>
                <p className="ludokinggoti">
                  4th Goti:{" "}
                  <span>
                    {/* {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L3"
                        ] || "0"
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L3"
                        ] || "0"
                      : ""}
                    {roomCodeData?.data?.result?.aPlayers?.[0]?._userId ===
                    roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                          "L3"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[1]?.aTokens?.[
                            "L3"
                          ] === -1
                        ? " (Not Opened)"
                        : "" || ""
                      : roomCodeData?.data?.result?.aPlayers?.[1]?._userId ===
                        roomCodeData?.ownerId
                      ? roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                          "L3"
                        ] === 56
                        ? " (Red)"
                        : roomCodeData?.data?.result?.aPlayers?.[0]?.aTokens?.[
                            "L3"
                          ] === -1
                        ? " (Not Opened)"
                        : "" || ""
                      : ""} */}
                    {roomCodeData?.tokenPositions?.player?.L3}
                  </span>
                </p>
              </div>
              {data?.Acceptor_status_Updated_at && (
                <div>
                  <p>
                    Status Updated At:{" "}
                    <span>
                      {moment(data?.Acceptor_status_Updated_at).format("LLL")}
                    </span>
                  </p>
                </div>
              )}
              {data?.Acceptor_status_reason && (
                <div>
                  <p>
                    Cancel Reason: <span>{data?.Acceptor_status_reason}</span>
                  </p>
                </div>
              )}
              <Row gutter={[20, 20]} className="mb-20">
                {data?.Acceptor_screenshot && (
                  <Col span={12}>
                    <div>
                      <p>Win Screenshot:</p>
                      <img
                        src={`https://khiladiludo.s3.ap-south-1.amazonaws.com/${data?.Acceptor_screenshot}`}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="not loaded"
                      />
                    </div>
                  </Col>
                )}
                {data?.Acceptor_Start_screenshot && (
                  <Col span={12}>
                    <div>
                      <p>Game Start Screenshot:</p>
                      <img
                        src={`https://khiladiludo.s3.ap-south-1.amazonaws.com/${data?.Acceptor_Start_screenshot}`}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="not loaded"
                      />
                    </div>
                  </Col>
                )}
                {data?.Acceptor_OneGoti_screenshot && (
                  <Col span={12}>
                    <div>
                      <p>One Goti Open:</p>
                      <img
                        src={`https://khiladiludo.s3.ap-south-1.amazonaws.com/${data?.Acceptor_OneGoti_screenshot}`}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="not loaded"
                      />
                    </div>
                  </Col>
                )}
              </Row>
              {(data?.Status == "pending" ||
                data?.Status == "conflict" ||
                (data?.Status === "running" && all_admin)) && (
                <div className="form-group">
                  <Row align={"middle"} gutter={[20, 20]}>
                    <Col xs={12} lg={6}>
                      <Button
                        onClick={() => {
                          updateByAdmin(data?.Accepetd_By?._id);
                        }}
                        className="w-100"
                      >
                        Win
                      </Button>
                    </Col>

                    <Col xs={12} lg={6}>
                      <Button
                        onClick={() => {
                          updateByAdmin(data?.Created_by?._id);
                        }}
                        className="w-100"
                      >
                        Lose
                      </Button>
                    </Col>
                  </Row>
                  <div>
                    <p>Add Penalty</p>
                    <Row gutter={16}>
                      <Col>
                        <Button
                          onClick={() => {
                            setPaneltyId(data?.Accepetd_By?._id);
                            setIsVisible(true);
                          }}
                        >
                          Add Penalty
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default React.memo(ViewChallenge);
