import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import CustomLayout from "../components/layout";
import Dashboard from "../pages/dashboard";
import DepositHistory from "../pages/transactionManager/DepositHistory";
import WithdrawalRequest from "../pages/transactionManager/WithdrawalRequest";
import ViewUser from "../pages/userManager/ViewUser";
import ConflictChallenges from "../pages/challengeManager/ConflictChallenges";
import ViewChallenge from "../pages/challengeManager/ViewChallenge";
import Contacts from "../pages/contact/Contacts";
import AllChallenge from "../pages/challengeManager/AllChallenge";
import CancelledChallenge from "../pages/challengeManager/CancelledChallange";
import CompletedChallenge from "../pages/challengeManager/CompletedChallenge";
import RunningChallenge from "../pages/challengeManager/RunningChallenge";
import DropChallenge from "../pages/challengeManager/DropChallenge";
import NewChallenge from "../pages/challengeManager/NewChallenge";
import AllUsers from "../pages/userManager/AllUsers";
import GujratUsers from "../pages/userManager/GujratUser";
import PendingKYC from "../pages/userManager/PendingKYC";
import RejectKYC from "../pages/userManager/RejectKYC";
import AllAdmin from "../pages/adminManager/AllAdmin";
import AddNewAdmin from "../pages/adminManager/AddNewAdmin";
import AdminEarnings from "../pages/AdminEarnings";
import VerifiedKYC from "../pages/userManager/VerifiedKYC";
import WithdrawalReport from "../pages/reports/WithdrawalReport";
import BonusReport from "../pages/reports/BonusReport";
import PenaltyReport from "../pages/reports/PenaltyReport";
import DepositReport from "../pages/reports/DepositReport";
import BonusHistory from "../pages/transactionManager/BonusHistory";
import WithdrawalHistory from "../pages/transactionManager/WithdrawHistory";
import Notification from "../pages/notification";
import PenaltyBonus from "../pages/transactionManager/PenaltyBonus";
import API_MANAGER from "../API";
import { message } from "antd";
import BlockedUsers from "../pages/userManager/BlockedUsers";
import SiteSetting from "../pages/userManager/SiteSetting";
import CheckChallange from "../pages/challengeManager/CheckChallange";
import RedeemHistory from "../pages/transactionManager/ReedemHistory";
import UserWithNoReferral from "../pages/userManager/UserWithNoReferral";
import AllmismatchUsers from "../pages/userManager/AllmismatchUsers";
import AllNegativeHoldUsers from "../pages/userManager/AllNegativeHoldUsers";
import ManualWithdrawalRequest from "../pages/transactionManager/ManualWithdrawalRequest";
import ManualWithdrawalHistory from "../pages/transactionManager/ManualWithdrawHistory";
import ManualWithdrawalReport from "../pages/reports/ManualWithdrawalReport";
// import StarClassicChallenge from "../pages/challengeManager/StarClassic";
// import StarClassicCancelledChallenge from "../pages/challengeManager/StarClassicCanceledChallenge";
// import StarClassicCompletedChallenge from "../pages/challengeManager/StarClassicCompletedChallenge";
// import StarClassicPlayingChallenge from "../pages/challengeManager/StarClassicRunningChallenge";
// import StarClassicDropChallenge from "../pages/challengeManager/StarClassicDropChallenge";
// import StarClassicWaitingChallenge from "../pages/challengeManager/StarLudoWaitingChallenge";
// import StarClassicAllChallenge from "../pages/challengeManager/StarClassicAllChallenge";
// import StarClassicMatchedChallenge from "../pages/challengeManager/StarClassicMatchedChallenge";
// import ViewStarClassicChallenge from "../pages/challengeManager/ViewStarClassicChallenge";
import AdminPanelty from "../pages/AdminPanelty";
import HELPERS from "../utils/helper";
import AdminPaneltyReport from "../pages/reports/AdminPaneltyReport";
import RedHighLightUsers from "../pages/userManager/RedHighLightUsers";
import DummyGames from "../pages/DummyGames";
const CustomRoutes = () => {
  const [data, setData] = useState({});
  const [permissions, setPermissions] = useState({});
  const getUser = async () => {
    try {
      const response = await API_MANAGER.getUserDetail();
      const decryptedData = await HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);
      let temp = {};
      decryptedData?.Permissions?.map((item) => {
        temp[item?.Permission] = item?.Status;
      });
      setPermissions(temp);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <CustomLayout permissions={permissions}>
      <Routes>
        {permissions?.dashboard && (
          <Route
            path="/dashboard"
            element={<Dashboard today_filtrers={permissions?.today_filtrers} />}
          />
        )}
        {permissions?.dashboard && (
          <Route
            path="/admin-panelty"
            element={<AdminPanelty permissions={permissions} />}
          />
        )}
        {permissions?.dashboard && (
          <Route
            path="/admin-panelty-report"
            element={<AdminPaneltyReport />}
          />
        )}
        {permissions?.admin_earnings && (
          <Route path="/earnings" element={<AdminEarnings />} />
        )}
        {permissions?.deposit_history && (
          <Route
            path="/deposit-history"
            element={<DepositHistory view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.deposit_history && (
          <Route
            path="/redeem-history"
            element={<RedeemHistory view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.panelty_and_bonus && (
          <Route
            path="/penalty-bonus"
            element={<PenaltyBonus view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.bonus_history && (
          <Route
            path="/bonus-history"
            element={<BonusHistory view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.withdrawal_history && (
          <Route
            path="/withdrawal-history"
            element={<WithdrawalHistory view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.withdrawal_history && (
          <Route
            path="/manual-withdrawal-history"
            element={
              <ManualWithdrawalHistory view_user={permissions?.view_user} />
            }
          />
        )}
        {permissions?.withdrawal_request && (
          <Route
            path="/withdrawal-request"
            element={<WithdrawalRequest view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.withdrawal_request && (
          <Route
            path="/manual-withdrawal-request"
            element={
              <ManualWithdrawalRequest view_user={permissions?.view_user} />
            }
          />
        )}
        {permissions?.view_user && (
          <Route
            path="/user/view/:id"
            element={<ViewUser permissions={permissions} />}
          />
        )}
        {permissions?.conflict_games && (
          <Route path="/conflict-challenges" element={<ConflictChallenges />} />
        )}
        <Route
          path="/view-challenges/:id"
          element={
            <ViewChallenge
              view_user={permissions?.view_user}
              all_admin={permissions?.all_admin}
            />
          }
        />
        {/* <Route
          path="/star-classic-view-challenges/:id"
          element={
            <ViewStarClassicChallenge view_user={permissions?.view_user} />
          }
        /> */}
        {/* {permissions?.site_setting && (
          <Route
            path="/star-classic-challenges"
            element={<StarClassicChallenge />}
          />
        )} */}
        {permissions?.all_games && (
          <Route path="/all-challenges" element={<AllChallenge />} />
        )}
        {permissions?.cancelled_games && (
          <Route
            path="/cancelled-challenges"
            element={<CancelledChallenge />}
          />
        )}
        {permissions?.completed_games && (
          <Route
            path="/completed-challenges"
            element={<CompletedChallenge />}
          />
        )}
        {permissions?.running_games && (
          <Route path="/running-challenges" element={<RunningChallenge />} />
        )}
        {permissions?.new_games && (
          <Route path="/new-challenges" element={<NewChallenge />} />
        )}
        <Route path="/drop-challenges" element={<DropChallenge />} />
        {/* {permissions?.classic_cancelled_games && (
          <Route
            path="/star-classic-cancelled-challenges"
            element={<StarClassicCancelledChallenge />}
          />
        )} */}
        {/* {permissions?.classic_completed_games && (
          <Route
            path="/star-classic-completed-challenges"
            element={<StarClassicCompletedChallenge />}
          />
        )} */}
        {/* {permissions?.classic_playing_games && (
          <Route
            path="/star-classic-playing-challenges"
            element={<StarClassicPlayingChallenge />}
          />
        )}
        {permissions?.classic_drop_games && (
          <Route
            path="/star-classic-drop-challenges"
            element={<StarClassicDropChallenge />}
          />
        )}
        {permissions?.classic_matched_games && (
          <Route
            path="/star-classic-matched-challenges"
            element={<StarClassicMatchedChallenge />}
          />
        )}
        {permissions?.classic_all_games && (
          <Route
            path="/star-classic-all-challenges"
            element={<StarClassicAllChallenge />}
          />
        )}
        {permissions?.classic_waiting_games && (
          <Route
            path="/star-classic-waiting-challenges"
            element={<StarClassicWaitingChallenge />}
          />
        )}{" "} */}
        {permissions?.conflict_games && (
          <Route path="/check-challenge" element={<CheckChallange />} />
        )}
        {permissions?.all_users && (
          <Route
            path="/user-all"
            element={<AllUsers view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.all_users && (
          <Route
            path="/user-mismatch-all"
            element={<AllmismatchUsers view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.all_users && (
          <Route
            path="/user-negative-hold-all"
            element={
              <AllNegativeHoldUsers view_user={permissions?.view_user} />
            }
          />
        )}
        {permissions?.all_users && (
          <Route path="/user-gujrat" element={<GujratUsers />} />
        )}
        {permissions?.all_users && (
          <Route
            path="/user-withoutreferral"
            element={<UserWithNoReferral />}
          />
        )}
        {permissions?.all_users && (
          <Route path="/red-highlight-users" element={<RedHighLightUsers />} />
        )}
        {permissions?.blocked_users && (
          <Route
            path="/blocked-users"
            element={<BlockedUsers view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.all_admin && (
          <Route path="/admin-all" element={<AllAdmin />} />
        )}
        {permissions?.add_new_admin && (
          <Route path="/admin-register" element={<AddNewAdmin />} />
        )}
        {permissions?.pending_kyc && (
          <Route
            path="/pending-kyc"
            element={<PendingKYC view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.reject_kyc && (
          <Route
            path="/reject-kyc"
            element={<RejectKYC view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.verified_kyc && (
          <Route
            path="/verified-kyc"
            element={<VerifiedKYC view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.site_setting && (
          <Route path="/site-setting" element={<SiteSetting />} />
        )}
        {permissions?.site_setting && (
          <Route path="/dummy-games" element={<DummyGames />} />
        )}
        {permissions?.deposit_report && (
          <Route
            path="/report-deposit"
            element={<DepositReport view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.withdrawal_report && (
          <Route
            path="/report-withdraw"
            element={<WithdrawalReport view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.withdrawal_report && (
          <Route
            path="/manual-report-withdraw"
            element={
              <ManualWithdrawalReport view_user={permissions?.view_user} />
            }
          />
        )}
        {permissions?.bonus_report && (
          <Route
            path="/report-bonus"
            element={<BonusReport view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.penalty_report && (
          <Route
            path="/report-penalty"
            element={<PenaltyReport view_user={permissions?.view_user} />}
          />
        )}
        {permissions?.notification && (
          <Route
            path="/notification"
            element={<Notification userData={data} />}
          />
        )}{" "}
        {permissions?.contact && (
          <Route path="/contacts" element={<Contacts />} />
        )}
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </CustomLayout>
  );
};

export default CustomRoutes;
