import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import API_MANAGER from "../../API";
import HELPERS from "../../utils/helper";

function Settings() {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const getSettings = async () => {
    try {
      const response = await API_MANAGER.getAllSettings();
      const decryptedData = HELPERS.decrypt(response?.data?.data);

      setData(decryptedData);
      form.setFieldsValue({
        withdrawaltype: decryptedData?.withdrawaltype,
        depositType: decryptedData?.depositType,
        isUPIGateway: decryptedData?.isUPIGateway,
        isRoomCodeByPass: decryptedData?.isRoomCodeByPass,
        isKVMPay: decryptedData?.isKVMPay,
        isMSPay: decryptedData?.isMSPay,
        isPhonePe: decryptedData?.isPhonePe,
        isTMPay: decryptedData?.isTMPay,
        isDeposit: decryptedData?.isDeposit,
        depositErrorMsg: decryptedData?.depositErrorMsg,
        createBattelMsg: decryptedData?.createBattelMsg,
        withdrawMsg: decryptedData?.withdrawMsg,
        addCashMsg: decryptedData?.addCashMsg,
        homePageMsg: decryptedData?.homePageMsg,
        allActionClose: decryptedData?.allActionClose,
        isReferralOff: decryptedData?.isReferralOff,
        manualWithdrawalAMount: decryptedData?.manualWithdrawalAMount,
        whatsappNumber: decryptedData?.whatsappNumber,
      });
      if (decryptedData?.isUPIGateway) {
        form.setFieldsValue({ scannerName: decryptedData?.scannerName });
      } else if (!decryptedData?.isUPIGateway) {
        form.setFieldsValue({ scannerName: null });
      }
    } catch (error) {}
  };
  const handleUpadte = async (values) => {
    try {
      await API_MANAGER.updateAllSettings(data?._id, values);
      getSettings();
      message.success("Settings updates successfully.");
    } catch (error) {
      message.error("Something went wrong.");
    }
  };
  useEffect(() => {
    getSettings();
  }, []);
  return (
    <div>
      <p className="pageHeading">Settings</p>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleUpadte}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name={"withdrawaltype"}
              label="Withdrawal Type"
              rules={[
                { required: true, message: "Please select withdrawal type" },
              ]}
            >
              <Select
                placeholder="Select withdrawal type"
                options={[
                  { label: "Manual", value: "manual" },
                  { label: "Auto", value: "autopayout" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"depositType"}
              label="Deposit Type"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  // { label: "KVM Pay", value: "kvm" },
                  { label: "Phonepe Business", value: "ppb" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isDeposit"}
              label="Deposit"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name={"isUPIGateway"}
              label="UPI Gateway"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                onChange={(e) => {
                  if (e) {
                    form.setFieldsValue({ scannerName: null });
                  }
                }}
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"scannerName"}
              label="Scanner Name"
              rules={[{ required: true, message: "Please enter scanner name" }]}
            >
              <Input placeholder="Enter Scanner Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isRoomCodeByPass"}
              label="Roomcode Bypass"
              rules={[
                { required: true, message: "Please select roomcode bypass" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"allActionClose"}
              label="All Action Off"
              rules={[{ required: true, message: "Please select" }]}
            >
              <Select
                placeholder="Select"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isReferralOff"}
              label="Referral"
              rules={[{ required: true, message: "Please select" }]}
            >
              <Select
                placeholder="Select"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"manualWithdrawalAMount"}
              label="Manual Withdrawal Amount"
              rules={[
                { required: true, message: "Enter manual withdrawal amount" },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter manual withdrawal amount"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"whatsappNumber"}
              label="WhatsApp Number"
              rules={[{ required: true, message: "Enter whatsapp number" }]}
            >
              <Input
                type="number"
                placeholder="Enter manual withdrawal amount"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"depositErrorMsg"} label="Deposit Error Message">
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"homePageMsg"} label="Home Page Message">
              <Input placeholder="Enter home page  message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"createBattelMsg"}
              label="Create Battel Page Message"
            >
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"withdrawMsg"} label="Withdraw Page Message">
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"addCashMsg"} label="Deposit Page Message">
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" className="secondary_button">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Settings;
